.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    font-size: 24px;
    background-color: #FE8B1E;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }
  
  .scroll-to-top.visible {
    opacity: 1;
    visibility: visible;
  }
  