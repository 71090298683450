.container{
	background-color: #fff;
	overflow: hidden;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.hourglass{
	position: relative;
	height: 80px;
	width: 80px;
	top: 28%;
	top: -webkit-calc(50% - 43px);
	top: calc(50% - 43px);
	left: 35%;
	left: -webkit-calc(50% - 43px);
	left: calc(50% - 43px);
	border: 3px solid #FE8B1E;
	border-radius: 80px;
	-webkit-transform-origin: 50% 50%;
			transform-origin: 50% 50%;
	-webkit-animation: hourglass 1.2s ease-in-out infinite;
			animation: hourglass 1.2s ease-in-out infinite;
}

.hourglass:before{
	content: "";
	position: absolute;
	top:8px;
	left: 18px;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 37px 22px 0 22px;
	border-color: #FE8B1E transparent transparent transparent;
}
.hourglass:after{
	content: "";
	position: absolute;
	top: 35px;
	left: 18px;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 0 22px 37px 22px;
	border-color: transparent transparent #FE8B1E transparent;
}

@-webkit-keyframes hourglass{
    0%{-webkit-transform:rotate(0deg);}
    100%{-webkit-transform:rotate(180deg);}
}

@keyframes hourglass{
    0%{transform:rotate(0deg);}
    100%{transform:rotate(180deg);}
}