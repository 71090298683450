@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
code {
  font-family: 'Roboto', sans-serif;
}
.main{
  flex: 1;
}

.footer {
  flex-shrink: 0;
}

.container{
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  color: black;
  min-height: 100vh;

  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  flex: 1;
}




a{
  text-decoration: none;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
}